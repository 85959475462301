if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function(){

    // Hidden the specials checkbox on footer form
    let form_id_2762 = document.querySelector( "#form_id-2762" )
    if( form_id_2762 ){
        let check = form_id_2762.querySelectorAll( ".gdpr-wrap .field.gdpr.clearfix" )[1]
        check.classList.add( "hidden" );
        document.querySelector( ".hidden_label .form-module__field--label" ).classList.add( "hidden" )
    }

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
    paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));
    
    $('a[href*=".pdf"]').each(function(event) {
	   $(this).attr('target','_blank');
	});

    $('.sym-slides').slick();
    
    $('.gallery-slider').slick({
        fade: true,
        dots: true,
        slidesToShow: 1,
        arrows: false,
        draggable: true
    });
    
    $('.review-slider').slick({
        arrows:false,
        autoplay:true,
        autoplaySpeed:4000,
        responsive: [
            {
                breakpoint: 768, 
                settings: {
                    arrows: false, 
                    dots:true
                }
            },
            {
                breakpoint: 480, 
                settings: {
                    arrows: false, 
                    dots:true
                }
            }
        ]
    });

    $('.easy-scroll').click(function () { 
        var target = $(this).attr("href");
        if($('body.main').length > 0) { var padding = 0; } else { var padding =$('header').height() + 100; }
        $('html, body').animate({scrollTop: $(target).offset().top - padding}, 1000);
        return false;
    });

    // Menu Filter Function

    const menuTargets = document.querySelectorAll(".menu-filter_item");
    const menuFilters = document.getElementById("menu-filters");
    
    
    const animateMenu = (menu) => {
        const endMenuAnimation = () => {
            menu.classList.remove("active");
            menu.removeEventListener("animationend", endMenuAnimation);
        }

        menu.classList.add("active");
        menu.addEventListener("animationend", endMenuAnimation);

    }
    
    if (menuFilters) {
        menuFilters.addEventListener("change", function() {
            if (this.value == "all") {
                menuTargets.forEach(target => {target.classList.remove("hidden"), animateMenu(target)});
            } else {
                menuTargets.forEach(target => target.classList.add("hidden"));

                let current = document.getElementById(this.value);

                current.classList.remove("hidden");
                animateMenu(current);

                for (const child of current.children) {
                    if (!child.classList.contains("fade-in")) child.classList.add("fade-in"); 
                }
            }
        })
    }
})();