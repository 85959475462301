function slideNextContent(btn,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        $this.next().slideToggle();
    });
}
function showContent(btn,content,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        content.fadeToggle();
    });
}

$(function(){	

	//checkflipbook
	if($('.sub-menu').length > 0){
		$('.sub-menu a').each(function(){
			if($(this).attr('href') == '#flipbookPopUp'){
				$(this).attr('href', 'https://2486634c787a971a3554-d983ce57e4c84901daded0f67d5a004f.ssl.cf1.rackcdn.com/ocean-edge-resort-golf-club/media/brochure-compressed-5e8231c38ada1.pdf');
				$(this).attr('target','_blank');
				$(this).removeClass('fancybox');
			}
		});
	}

	$('.close-res').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');

		$('.booking-widget').stop().animate({right:'100%'},{queue:false,duration:300});
	});
	setTimeout(() => {
		$('header').css('opacity','1');
		$('.top-content').css('opacity','1');
		$('section.content').css('opacity','1');
		$('.footer').css('opacity','1');
	}, 200);
	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation
	$('#menu-btn').click(function(event) {
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({right:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	if($('body#gallery').length > 0){
		$('header').addClass('scroll_class');
	}

	$('.close-menu').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({right:'100%'},{queue:false,duration:300});
	});

	$('.arrow-home').click(function(event) {
		$('html, body').animate({
			scrollTop: ($('.top-content').height() - 99) + 'px'
		}, 200, 'swing');
	});
	//Add value in email-signup Interest field
	$('#footer .top__footer--interest li').click(function(){
		let elements_option = document.querySelectorAll('#email-interest .option:checked');
		let selected_items = [];
		for(var i = 0; i < elements_option.length; i++){
			selected_items.push(elements_option[i].value)
		}
		$('#email-signup_INTEREST').val(selected_items);
	});
	$('#footer #email-signup [role=group]').appendTo('#footer .top__footer--policies');
	$('#footer #email-signup button').insertAfter('.top__footer--policies [role=group]');
	$('#footer #email-signup_state').insertBefore('#footer #email-signup_country');
	$("#footer .top__footer--policies button").click(function() {
		if($('#email-signup_INTEREST').val() == ''){
			$('.top__footer--interest h4').css('color','red');
			return false;
		}else if(!$("#privacy_termsemail-signup").is(':checked')){
			$('label[for=privacy_termsemail-signup]').css('color','red');
			return false;
		}else{
			$('.top__footer--interest h4').css('color','#333');
			$('label[for="privacy_termsemail-signup"]').css('color','#333');
			$("#footer #email-signup").submit();
			setTimeout(() => {
				$('.top__footer--interest').css('opacity','0');
				$('.top__footer--interest').css('height','0');
				$('.top__footer--policies').css('opacity','0');
				$('.top__footer--policies').css('height','0');
			}, 500);
		}
	});

	// *only* if we have anchor on the url
	if(window.location.hash) {
		var offset = -70;
		// smooth scroll to the anchor id
		$('html, body').animate({
			scrollTop: ($(window.location.hash).offset().top + offset) + 'px'
		}, 1000, 'swing');
		if(window.location.hash.indexOf('#three__quads--')>-1){
			setTimeout(() => {
				$(window.location.hash+' a').trigger("click");
			}, 1100);
		}
	};

	//SUBNAVIGATION

	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');

	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        }
    });

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	//Prevents fancybox links from opening
	$("a.fancybox").on("click", function(e){
		e.preventDefault();
	});

	//DROPDOWN STYLE
	$('select').each(function(){
		if(!$(this).parent().hasClass('dropdown')){
			$('select:not(.nice-select-disabled)').niceSelect();
		}
	});
	/*END OF CUSTOM SCRIPTS
	****************************************************************/
});

function initSlider(){
	setTimeout(function(){
		$('.slider-tabs').slick({
			arrows:true,
			dots:false,
			slidesToShow:1,
			slidesToScroll:1,
			fade:true,
			autoplay:false,
			draggable:true,
		});
		$('.slider-tabs').slick({
			arrows:true,
			dots:false,
			slidesToShow:1,
			slidesToScroll:1,
			fade:true,
			autoplay:false,
			draggable:true,
		});
		
	},500)
	
}